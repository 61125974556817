import React from "react";
import { useState } from "react";
import styles from '../../styles/AudioChatPage.module.css';
import { useNavigate } from 'react-router-dom';
export default function SkipButton({matched, clickFunction, reload}){
    const [counter, setCounter] = useState(0);
    const internalClickFunction = () => {
        if(!matched){
            reload(prevReload => !prevReload);
        }
        setCounter(prevCounter => prevCounter === 0 ? 1 : 0);
        if (counter === 1)
            clickFunction();
    }

    return (
    <button className={styles.skip} onClick={internalClickFunction}>
        {matched ? (
            counter === 0 ? (
            <>
                <svg width="66" height="61" viewBox="0 0 66 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M62.1023 27.4098C63.9653 29.0063 63.9653 31.8883 62.1023 33.4847L41.4777 51.1576C38.8829 53.381 34.875 51.5373 34.875 48.1202V12.7744C34.875 9.35722 38.8829 7.51348 41.4777 9.73694L62.1023 27.4098Z" fill="black"/>
                <path d="M37.4846 27.4098C39.3476 29.0063 39.3476 31.8883 37.4846 33.4847L16.8601 51.1576C14.2652 53.381 10.2573 51.5373 10.2573 48.1202L10.2573 12.7744C10.2573 9.35722 14.2652 7.51348 16.8601 9.73694L37.4846 27.4098Z" fill="black"/>
                </svg>
                <span>skip</span>
            </>
        ) : (<>
                <p style={{ fontSize: "1em" }}>Are you sure?</p>
                <span style={{ textDecoration: "underline", fontWeight: "normal", fontSize: "0.9em" }}>yes</span>
            </>)) : (
        <span>New<br/>Chat</span>
        )}         
    </button>
    );
}