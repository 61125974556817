import loading from '../assets/loading.webp';
import styles from '../styles/ChatPage.module.css';
export default function Loading() {
    return (
        <div className={styles.loading}>
            <img src={loading} alt="loading" width="80px" />
            <div className={styles.info}>
                <h3>We are connecting you with a <span>random</span> person...</h3>
            </div>
        </div>
    )
}