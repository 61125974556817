import React from "react";

export default function FiltersButton({ clickFunction }){
    return (
    <button onClick={clickFunction}>
        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6027 1.68953C13.2107 -0.133555 15.7893 -0.133553 16.3973 1.68953L18.4221 7.76137C18.6944 8.57793 19.4586 9.12868 20.3194 9.12868H26.7589C28.7089 9.12868 29.5061 11.6344 27.9145 12.761L22.7949 16.3853C22.0757 16.8944 21.7744 17.8144 22.0532 18.6503L24.0297 24.5773C24.6411 26.4108 22.5543 27.9591 20.9768 26.8424L15.6556 23.0754C14.9632 22.5852 14.0368 22.5852 13.3444 23.0754L8.02319 26.8424C6.44568 27.9591 4.35889 26.4108 4.97031 24.5773L6.94679 18.6503C7.22556 17.8144 6.92433 16.8944 6.20508 16.3853L1.0855 12.7611C-0.506066 11.6344 0.29107 9.12868 2.24108 9.12868H8.68064C9.54142 9.12868 10.3056 8.57793 10.5779 7.76137L12.6027 1.68953Z" fill="#080808"/>
        </svg>
    </button>
    );
}