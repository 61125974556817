import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import report from '../assets/report.webp';
import styles from '../styles/ReportPopUp.module.css';

export default function ReportPopUp({show, setShow, yesFunction, word}){
    const popUp = useRef();
    useEffect(() => {
        if(!popUp.current) return;
        if(show){
            popUp.current.style.display = 'flex';
        }
        else{
            popUp.current.style.display = 'none';
        }
    }, [show]);

    const clickNo = () => setShow(false);

    return createPortal(
        <>
        <div className={styles.reportPopUp} ref={popUp}>
            <img src={report} alt="report" />
            <h2><span>Are you </span>sure <span>you want to</span><br />report <span>this user ?</span></h2>
            <p>A {word} of the screen will be sent to the support!</p>
            <div>
                <button onClick={clickNo}>NO</button>
                <button onClick={()=>{
                    setShow(false);
                    yesFunction();
                }}>YES</button>
            </div>
        </div>
        { show && <div className={styles.blurFilter}></div>}
        </>,
        document.body
    );
}