import React from 'react';

export function MuteAudioButton({ mute, clickFunction }) {
  return (
        <button id="muteMic" onClick={clickFunction}>
        {!mute ? (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="18.5001" width="9" height="14" rx="4.5" fill="#080808"/>
            <rect x="23" y="18.5001" width="9" height="14" rx="4.5" fill="#080808"/>
            <path d="M2.5 23.0001C2.49987 -3.4062 29.5 -6.54981 29.5 23.0001" stroke="black" strokeWidth="4" strokeLinecap="round"/>
        </svg>
        ) : (         
            <HeadphoneOffSvg />
        )}
        </button>
  );
}
export function HeadphoneOffSvg(){
    return (        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="9" y="26" width="9" height="14" rx="4.5" fill="#080808"/>
    <rect x="32" y="26" width="9" height="14" rx="4.5" fill="#080808"/>
    <path d="M11.5 31.0001C11.4999 4.5938 38.5 1.45019 38.5 31.0001" stroke="black" strokeWidth="4" strokeLinecap="round"/>
    <g filter="url(#filter0_d_1429_130)">
        <path d="M6.78381 8.17865L43.5733 43.7059" stroke="black" strokeWidth="5" strokeLinecap="round"/>
    </g>
    <defs>
        <filter id="filter0_d_1429_130" x="0.283813" y="0.67865" width="49.7896" height="48.5272" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="-1"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.922933 0 0 0 0 0.354757 0 0 0 0 0.31849 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1429_130"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1429_130" result="shape"/>
        </filter>
    </defs>
</svg>);
}