import '../styles/InfoPopUp.css';

export default function PopUp({style}){
  return (
    <div className='container' style={style}>
      <div className='popup'>
            <p>
            <h2 className="info">USER <span>CONDITIONS</span>!</h2>
      <span>Welcome to our site.</span><br/>
      By using MeYou, you state that the following statements are accurate:
      <ul>
        <li>I am at least 14-years old so I can use MeYou.social.</li>
        <li>I am voluntarily choosing to access this area of the Website, because I want to view, read, or hear the various materials that are available.</li>
        <li>I grant to not offend other users or show explicit content (as drugs, pornographic or violent content).</li>
        <li>I understand and will abide by the standards and laws of my community.</li>
        <li>By logging on and viewing any part of the Website, I will not hold the Website's owners or its employees responsible for any materials located on the Website.</li>
      </ul>
We hope you'll meet lots of nice people and form new friendships! Good luck!
Every time you access or use MeYou, you agree to these Terms, our Privacy Policy, and all other applicable terms that apply to you. If you do not agree to these Terms, please do not use our site.
      <br/><span>Your License to Use MeYou</span><br/>
      We grant you a limited, non-exclusive, non-transferable, and revocable license to use our Service subject to these Terms, our Privacy Policy, and other policies and applicable law.
      <br/><span>Age and Legal Capacity</span><br/>
      You represent that you are at least 14 years old and have the legal capacity to enter into the agreement set forth in these Terms. No one under the age of 14 may use or access MeYou. You may not use MeYou if you do not have the legal capacity to enter into contracts or if doing so would be prohibited under Italian law. If you are a resident of the European Economic Area ("EEA"), you may only use MeYou if you have reached the age at which you can consent to data processing under the laws of your country or if we have verifiable parental consent for your use of MeYou.
      <br/><span>You Own What You Make</span><br/>
      We refer to the photos, comments, links, and other content you post as "User Content" You retain all rights in the User Content you post on MeYou and are solely responsible for it. Although the User Content belongs to you, several important limitations apply:You grant MeYou and our users a non-exclusive, royalty-free, transferable, sublicensable, worldwide license to use, store, display, reproduce, store, modify, create derivative works, perform and distribute your User Content for the purposes of using, accessing, operating, developing, and providing MeYou. Nothing in these Terms limits any other legal rights that MeYou may have in User Content, such as under other licenses. Third, we reserve the right to remove or modify User Content or to change the way it is used on MeYou for any reason. This includes User Content that we believe violates these Terms or other policies. Fourth, the content you share with others on MeYou may remain on MeYou even if you leave MeYou. We may secure, archive, and retain your User Content even if you terminate or deactivate your account or delete or remove certain User Content. MeYou and its users may continue to use, store, display, reproduce, pin, modify, and distribute any of your User Content to the extent permitted by these Terms or applicable laws.
      <br/><span>Your Responsibility for User Content</span><br/>
      You are solely responsible for your User Content and the consequences of putting it on MeYou. You acknowledge that you have all necessary rights, permissions, and consents to post User Content and to grant us the license above. In addition, you agree that your User Content does not violate these Terms or other policies of MeYou, including but not limited to our Community Guidelines, and does not infringe the rights of third parties, including but not limited to copyrights, trademarks, personality rights, or privacy rights. We reserve the right to remove or modify User Content at our sole discretion for any reason, including User Content that we believe violates these Terms or other policies.
      <br/><span>Your Responsibility for Your Account</span><br/>
      You are also responsible for the activities that occur on your MeYou account (IP connection). We recommend that you protect your account with a strong password and do not share it with others. You agree to promptly notify us of any unauthorized access or use of your account or other security breaches. We reserve the right at our sole discretion to restrict or terminate your access to your account for any reason, including but not limited to violations of these Terms or other policies.
      <br/><span>Your Use of MeYou</span><br/>
      You agree to use MeYou only for lawful purposes and to comply with all applicable laws and regulations. You may not use MeYou for illegal activities or share content that violates applicable laws or regulations. If you violate these Terms or other policies of MeYou, we may restrict or terminate your account and/or take legal action against you.
      <br/><span>Your Interactions with Other Users</span><br/>
      MeYou strives to create a safe environment for all users. However, we cannot guarantee that all users on MeYou are honest and trustworthy. You are solely responsible for your interactions with other users of MeYou. We recommend that you exercise caution and follow our safety tips.
      <br/><span>Your Complaints</span><br/>
      We take the rights of others seriously and encourage you to contact us if you believe that content on MeYou violates your rights. We will carefully review any legal complaints and take appropriate action if necessary.
      <br/><span>Termination</span><br/>
      We may suspend or terminate your license to use MeYou at any time and for any reason. In general, we will provide notice and the reason for termination or suspension. However, our termination or suspension may be immediate and without notice if we, in our sole discretion, believe that you have committed a serious violation of these Terms or our policies.
      <br/><span>Disclaimer of Warranties and Limitation of Liability</span><br/>
      MeYou is provided "as is" and "as available," without express or implied warranties of any kind. We expressly disclaim all warranties and conditions of any kind, including but not limited to implied warranties and conditions of merchantability, fitness for a particular purpose, and non-infringement. We do not review User Content in advance. MeYou assumes no responsibility and shall have no liability for User Content that you or any other person or third party posts or sends using our Service. You understand and agree that you may be exposed to User Content that is inaccurate, offensive, indecent, abusive, inappropriate for children, or otherwise unsuitable for your purpose.
      <br/><span>General Provisions</span><br/>
      Assignment
      These Terms and all rights and licenses granted herein may not be transferred or assigned by you, but may be transferred by MeYou without restriction. Any attempted transfer or assignment in violation of these Terms is null and void.
      <br/><span>Severability</span><br/>
      If any provision of these Terms is unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any remaining provisions. These Terms, together with our Privacy Policy and any additional agreements you enter into with MeYou.
      <br/><span>No Waiver</span><br/>
      No waiver of any provision of these Terms shall be deemed a further or continuing waiver of such provision or any other provision, and MeYou's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.
      <br/><span>Parties</span><br/>
      These Terms are a contract between you and MeYou. MeYou is the company that owns MeYou site.        
            </p>
          </div>
    </div>
      
  );
};