import logo from '../assets/logo.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef, useCallback } from 'react';
import io from 'socket.io-client';
import Loading from './Loading';
import {isMobile} from 'react-device-detect';
import styles from '../styles/ChatPage.module.css';
import ReportPopUp from './ReportPopUp';
import fire from '../assets/fire.png';
export default function ChatPage() {
    const [loading, setLoading] = useState(true);
    const [matched, setMatched] = useState(false);
    const [counter, setCounter] = useState(0);
    const [messages, setMessages] = useState([]);
    const [typing, setTyping] = useState(false);
    const [sameInterests, setSameInterests] = useState([]);
    const [sameLanguage, setSameLanguage] = useState(false);
    const [info, setInfo] = useState('');
    const socketRef = useRef();
    const chatHistoryRef = useRef();
    const inputRef = useRef();
    const [showReport, setShowReport] = useState(false);
    const navigate = useNavigate();
    const interestsRef = useRef();
    useEffect(() => {
        const language = navigator.language.split('-')[0];
        const interests = new URLSearchParams(window.location.search).get('interests');
        //window.history.replaceState({}, document.title, "/chat");
        const newSocket = io('https://meyou.social:4000',{query: `language=${language}` + (interests ? `&interests=${interests}` : '') });
        socketRef.current = newSocket;
        newSocket.on('match_found', (obj) => { 
            setMessages([]);
            setInfo('');
            setSameInterests(obj.sameInterests);
            setSameLanguage(obj.sameLanguage);
            setTimeout(() => {
                setLoading(false);
                setMatched(true);
                setTimeout(() => {
                    if(obj.sameInterests.length > 0){
                      showInterestPopUp(obj.sameInterests);
                    }
                  }, 500);
            }, 1500);
        });
        newSocket.on('disconnect', () => {
            setMatched(false);
        });
        newSocket.on('message', (msg) => {
            setMessages(list => [...list, { text: msg, isUser: false }]);
        });
        newSocket.on('typing', () => {
            console.log('typing');
            setTyping(true);
            setTimeout(() => {
                setTyping(false);
            }, 3500);
        });
        newSocket.on('user_left', () => {
            setInfo('Stranger left the chat.');
            setMatched(false);
            newSocket.disconnect();
        });
        return () => {
            newSocket.disconnect();
        };
    }, []);

    const onSkip = useCallback(() => {
        if(!matched) {
            setLoading(true);
            socketRef.current.connect();
            return;
        };
        setCounter(prevCounter => prevCounter === 0 ? 1 : 0);
        if (counter === 1) {
            setInfo('You left the chat.');
            socketRef.current.disconnect();
        }
    }, [matched,counter, setCounter, setInfo, socketRef, setLoading]);

    const sendMessage = (target) => {
        const message = target.value;
        if (message.replace(/\s/g, '').length === 0) return;
        target.value = "";
        setMessages(prevMessages => [...prevMessages, { text: message, isUser: true }]);
        socketRef.current.emit("message", message);
    }

    const buttonClick = () => {
        sendMessage(inputRef.current);
    }
    const showInterestPopUp = (interest) => {
        interestsRef.current.style.display = 'flex';
        interestsRef.current.querySelector('p').textContent = `INTEREST: ${interest}`;
      };
  
      const hideInterestPopUp = () => {
        interestsRef.current.style.display = 'none';
      };
    const handleKeyDown = useCallback((event) => {
        socketRef.current.emit("typing");
        if (event.key === "Enter" && !isMobile) {
            event.preventDefault();
            sendMessage(event.target);
        } else if (event.key === "Escape") {
            onSkip();
        }
    }, [onSkip, socketRef]);

    useEffect(() => {
        if (chatHistoryRef.current) {
            chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
        }
    }, [messages, info]);

    const setStyles = useCallback((action) => {
        const body = document.querySelector('body');
        const root = document.querySelector('#root');
        if(action === "add"){
            body.classList.add(styles.chat_background);
            root.classList.add(styles.root_chat);
        } else {
            body.classList.remove(styles.chat_background);
            root.classList.remove(styles.root_chat);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        setStyles("add");
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            setStyles("remove");
        };
    }, [handleKeyDown, setStyles]);

    const reportUser = () => {
        //implement report user
    };
    const clickReport = () => setShowReport(true);
    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                          <div className={styles.interests} ref={interestsRef}>
              <div>
                <img src={fire} alt="fire" />
                <div>
                  <h2>YOU HAVE FOUND A <span>MATCH</span>!</h2>
                  <p>INTEREST: </p>
                </div>
                <button onClick={hideInterestPopUp}>OK<svg width="15" height="15" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="7.08206" height="20.8941" rx="3.54103" transform="matrix(0.88373 -0.467997 0.508095 0.861301 -0.958374 9.88965)" fill="white" fill-opacity="0.99"/>
              <rect width="7.13434" height="30.6905" rx="3.56717" transform="matrix(-0.87063 -0.491938 0.523942 -0.851754 12.6896 28.4346)" fill="white" fill-opacity="0.99"/>
              </svg>
              </button>
              </div>
          </div>
                    <div className={styles.navbar}>
                    <svg onClick={() => {navigate("/")}} width="60" height="60" viewBox="0 0 41 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.3705 0.64669C19.7558 0.283532 20.3573 0.283533 20.7425 0.646691L35.5934 14.6473C36.252 15.2682 35.8126 16.3749 34.9074 16.3749H5.20559C4.30041 16.3749 3.86098 15.2682 4.51962 14.6473L19.3705 0.64669Z" fill="#81F7FB"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 8C11.2386 8 9 10.2386 9 13V28.7065C9 29.8111 9.89543 30.7065 11 30.7065H16.0622C16.0213 30.4772 16 30.2411 16 30V24C16 22.3431 17.3431 21 19 21H21C22.6569 21 24 22.3431 24 24V30C24 30.2411 23.9787 30.4772 23.9378 30.7065H29.1902C30.2947 30.7065 31.1902 29.8111 31.1902 28.7065V13C31.1902 10.2386 28.9516 8 26.1902 8H14Z" fill="#81F7FB"/>
                    </svg>

            <img src={logo} alt="logo" width="200px" />
                        <svg onClick={clickReport} width="60" height="60" viewBox="0 0 61 58" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginTop: '6px'}}>
                        <path d="M27.081 5.63071C28.6386 3.06549 32.3614 3.0655 33.919 5.63071L53.2243 37.4239C54.8429 40.0896 52.9239 43.5 49.8052 43.5H11.1948C8.07612 43.5 6.15707 40.0896 7.77571 37.4239L27.081 5.63071Z" fill="#81F7FB"/>
                        <path d="M32.8821 18.5455L32.4389 30.5455H28.5526L28.1094 18.5455H32.8821ZM30.4957 36.2727C29.8366 36.2727 29.2713 36.0426 28.7997 35.5824C28.3338 35.1165 28.1037 34.5511 28.1094 33.8864C28.1037 33.2386 28.3338 32.6847 28.7997 32.2244C29.2713 31.7642 29.8366 31.5341 30.4957 31.5341C31.1207 31.5341 31.6719 31.7642 32.1491 32.2244C32.6321 32.6847 32.8764 33.2386 32.8821 33.8864C32.8764 34.3295 32.7599 34.733 32.5327 35.0966C32.3111 35.4545 32.0213 35.7415 31.6634 35.9574C31.3054 36.1676 30.9162 36.2727 30.4957 36.2727Z" fill="#1B1B1B"/>
                        </svg>
                    </div>
                    <div className={styles.chat}>
                        <div className={styles['chat-history']} ref={chatHistoryRef}>
                            <div className={styles.info}>
                                <h3>You are chatting with a random <span>person</span>! Say something <span>good</span> :)</h3>
                                {sameLanguage && <h4>You both speak the same language
                                    {sameInterests.length > 0 && ` and like ${sameInterests.join(', ')}` }
                                    .</h4>}
                                {(!sameLanguage && sameInterests.length > 0) && <h4>You both like {sameInterests.join(', ')}.</h4>}
                            </div>
                            {messages.map((message, index) => (
                                <div key={index} className={`${styles['chat-message']} ${message.isUser ? styles.user : styles.stranger}`}>
                                    <div className={`${styles['chat-message-text']} ${message.isUser ? styles.us : styles.st}`}>
                                        <span>{message.text}</span>
                                    </div>
                                </div>
                            ))}
                            {info && (
                                <div className={styles['chat-message'] + ' ' + styles.end}>
                                    <span>{info}</span>
                                </div>
                            )}
                        </div>
                        <div className={styles['chat-form']}>
                            {typing && <span>Stranger is typing...</span>}
                            <div>
                                <button id="chat-skip" onClick={onSkip} className={counter === 1 ? styles.skip : ''}>
                                    {matched ? (
                                        counter === 0 ? (
                                            <>
                                                <FontAwesomeIcon icon={faForward} />
                                                <span>skip</span>
                                            </>
                                        ) : (
                                            <>
                                                <p style={{ fontSize: "1em" }}>Are you sure?</p>
                                                <span style={{ textDecoration: "underline", fontWeight: "normal", fontSize: "0.9em", marginTop: "-10px" }}>yes</span>
                                            </>
                                        )
                                    ) : (
                                        <span>New<br/>Chat  </span>
                                    )}
                                </button>
                                <textarea type="text" id="chat-input" placeholder="Message.." ref={inputRef}
                                 onKeyDown={handleKeyDown} disabled={!matched ? true : false} maxLength={1500}>
                                 </textarea>
                                <svg className={styles['send-icon']} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" onClick={buttonClick}>
                                    <path d="M83.4 226.6L304 256 83.4 285.4 0 480H64L512 256 64 32H0L83.4 226.6z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <ReportPopUp show={showReport} setShow={setShowReport} yesFunction={reportUser} word="snapshot" />
        </>
    );
};
