import React from 'react';

export function MuteMicButton({ mute, clickFunction }) {
  return (
      <button id="muteMic" onClick={clickFunction}>
        {!mute ? (
        <svg width="27" height="46" viewBox="0 0 27 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="10.9048" y="34" width="4.95238" height="10" fill="#080808"/>
            <rect x="1" y="46" width="4" height="26" rx="2" transform="rotate(-90 1 46)" fill="#080808"/>
            <rect x="6.92847" y="1.5" width="12.9048" height="28" rx="6.45238" fill="#080808" stroke="#D9D9D9" strokeWidth="3"/>
            <path d="M2.23804 24C2.23793 35.3169 24.5238 36.6642 24.5238 24" stroke="black" strokeWidth="4" strokeLinecap="round"/>
        </svg>
        ) : (         
          <MicOffSvg />
        )}
      </button>
  );
}

export function MicOffSvg(){
  return (        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="21.9048" y="34" width="4.95238" height="10" fill="#080808"/>
  <rect x="12.0001" y="46" width="4" height="26" rx="2" transform="rotate(-90 12.0001 46)" fill="#080808"/>
  <rect x="17.9286" y="1.5" width="12.9048" height="28" rx="6.45238" fill="#080808" stroke="#D9D9D9" strokeWidth="3"/>
  <path d="M13.2382 24C13.238 35.3169 35.5239 36.6642 35.5239 24" stroke="black" strokeWidth="4" strokeLinecap="round"/>
  <g filter="url(#filter0_d_1429_124)">
    <path d="M6.78387 8.17859L43.5734 43.7058" stroke="black" strokeWidth="5" strokeLinecap="round"/>
  </g>
  <defs>
    <filter id="filter0_d_1429_124" x="0.283875" y="0.678589" width="49.7896" height="48.5272" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="-1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.922933 0 0 0 0 0.354757 0 0 0 0 0.31849 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1429_124"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1429_124" result="shape"/>
    </filter>
  </defs>
</svg>);
}

