import React, { useState, useCallback, useEffect, useRef } from 'react';
import logo from '../assets/logo.webp';
import blue from '../assets/blue.webp';
import white from '../assets/white.webp';
import chat from '../assets/chat.webp';
import audio from '../assets/audio.webp';
import following from '../assets/following.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import InfoPopUp from './InfoPopUp';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/HomePage.module.css';

export default function HomePage() {
    const [activeIcon, setActiveIcon] = useState(0);
    const [popupVisibility, setPopupVisibility] = useState('hidden');
    const [optionsVisibility, setOptionsVisibility] = useState('hidden');
    const [interestsOpacity, setInterestsOpacity] = useState(0);
    const [interests, setInterests] = useState();
    const optionsRef = useRef(null);
    const buttonRef = useRef(null);
    const interestsRef = useRef(null);
    const bottomRef = useRef(null);
    const navigate = useNavigate();

    const changeOptionsVisibility = useCallback((visibility) => {
        if(visibility !== "hidden"){
            setOptionsVisibility(visibility);
            optionsRef.current?.style.setProperty("opacity", "1");
            return;
        }
        optionsRef.current?.style.setProperty("opacity", "0");
        setTimeout(() => {
            setOptionsVisibility(visibility);
        }, 500);
    }, [setOptionsVisibility, optionsRef]);

    const openOptions = () => {
        if (optionsVisibility === 'visible'){
            changeOptionsVisibility('hidden');
            buttonRef.current?.blur();
        }
        else{
            changeOptionsVisibility('visible');
        }
    }

    const openInterestMenu = useCallback(() => {
        if (interestsOpacity === 0) {
            interestsRef.current?.style.setProperty("display", "flex");
            setTimeout(() => {
                setInterestsOpacity(1);
            },10);
        } else {
            setTimeout(() => {
                interestsRef.current?.style.setProperty("display", "none");
            }, 500);
            setInterestsOpacity(0);
        }

    }, [interestsOpacity]);

    useEffect(() => {
        fetch("https://meyou.social:4000/data", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => res.json()).then((data) => {
            if (data.interest === true){
                bottomRef.current?.style.setProperty("display", "flex");
            }
        });
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) 
                optionsRef.current?.style.setProperty('top', `${buttonRef.current?.getBoundingClientRect().bottom-70}px`);
        };
        const handleClick = (e) => {
            if (!optionsRef.current?.contains(e.target) && !buttonRef.current?.contains(e.target)) {
                changeOptionsVisibility('hidden');
            }
            if(interestsOpacity === 1 && !bottomRef.current?.contains(e.target)){
                openInterestMenu();
            }
        };
        const handleKeydown = (e) => {
            if (e.key === 'Escape') {
                changeOptionsVisibility('hidden');
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        window.addEventListener('click', handleClick);
        window.addEventListener('keydown', handleKeydown);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('click', handleClick);
            window.removeEventListener('keydown', handleKeydown);
        };
    }, [optionsVisibility, interestsOpacity, changeOptionsVisibility, openInterestMenu]);

    const clickIcon = () => {
        if (activeIcon === 0) {
            setActiveIcon(1);
            setPopupVisibility('visible');
        } else {
            setActiveIcon(0);
            setPopupVisibility('hidden');
        }
    }
    const addInterest = () => {
        const input = interestsRef.current?.children[0];
        const btn = document.querySelector("#root > div > div:nth-child(3) > button > span");
        if (input.value !== ''){
            setInterests(input.value);
            if (btn.textContent === "Add an interest") {
                btn.textContent = "Edit interest";
            }
        }
        openInterestMenu();
    }

    const navigateToAudioChat = () => navigate('/audiochat'+(interests ? `?interests=${interests}` : ''));
    const navigateToChat = () => navigate('/chat'+(interests ? `?interests=${interests}` : ''));
    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <FontAwesomeIcon icon={faCircleExclamation} className={activeIcon === 0 ? styles.active : ''} onClick={clickIcon} />
                <FontAwesomeIcon icon={faCircleXmark} className={activeIcon === 1 ? styles.active : ''} onClick={clickIcon} />
            </div>
            <div className={styles.hero}>
                <img src={logo} alt="meyou logo" className={styles.logo} />
                <button ref={buttonRef} onClick={openOptions}><p>CONNECT WITH A <span>STRANGER</span>!</p></button>
                <div className={styles.omini}>
                    <img className={styles.omino} src={blue} alt='omino blue' />
                    <img className={styles.omino} src={white} alt='omino white' />
                </div>
                <div className={styles.options} ref={optionsRef} style={{ visibility: optionsVisibility }}>
                    <div onClick={navigateToAudioChat}>
                        <img src={audio} alt="audio icon" />
                        <span>AUDIO</span>
                    </div>
                    <div onClick={navigateToChat}>
                        <img src={chat} alt="chat icon" />
                        <span>CHAT</span>
                    </div>
                </div>
            </div>
            <div className={styles.bottom} ref={bottomRef}>
                <div className={styles.interest} style={{ opacity: interestsOpacity }} ref={interestsRef}>
                    <input type="text" placeholder="Your interests(,)..." />
                    <button onClick={addInterest}></button>
                </div>
                <button onClick={openInterestMenu}>
                    <img src={following} alt="following icon" />
                    <span>add an interest</span>    
                </button>
            </div>
            <InfoPopUp style={{ visibility: popupVisibility, opacity: popupVisibility === "hidden" ? 0:1 }} />
        </div>
    );
};